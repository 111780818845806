import React from "react";

const SkillsTwo = () => {
  return (
    <>
      <div className="shane_tm_section">
        <div className="shane_tm_skills">
          <div className="container">
            <div className="skills_inner">
              <div
                className="left"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <div className="shane_tm_title">
                  <h3>Ich habe umfassende Kenntnisse in der professionellen .NET Softwareentwicklung</h3>
                </div>
                <div className="text">
                  <p>
                    Ich arbeite an der Konzeption, der Entwicklung und dem Deployment von vielseitigen, langlaufenden Softwareprojekten mit Desktop-, Web- und Cloud-Technologien. Ich übe jede Phase des Entwicklungsprozesses mit Begeisterung aus, von der Anforderungsanalyse bis zur Inbetriebnahme.
                  </p>
                </div>
              </div>
              {/* End .left */}

              <div
                className="right"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <div className="tokyo_progress">
                  <div className="progress_inner">
                    <span>
                      <span className="label"> Softwareentwicklung (C# &amp; .NET)</span>
                      {/* <span className="number">95%</span> */}
                    </span>
                    <div className="background">
                      <div className="bar">
                        <div
                          className="bar_in"
                          style={{ width: 100 + "%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  {/* End .progress_inner */}

                  <div className="progress_inner">
                    <span>
                      <span className="label">Projektmanagement</span>
                      {/* <span className="number">80%</span> */}
                    </span>
                    <div className="background">
                      <div className="bar">
                        <div
                          className="bar_in"
                          style={{ width: 100 + "%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  {/* End .progress_inner */}

                  <div className="progress_inner">
                    <span>
                      <span className="label">Architektur und Systemdesign</span>
                      {/* <span className="number">85%</span> */}
                    </span>
                    <div className="background">
                      <div className="bar">
                        <div
                          className="bar_in"
                          style={{ width: 100 + "%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  {/* End .progress_inner */}
                </div>
              </div>
              {/* End .right */}
            </div>
          </div>
          {/* End .conainer */}
        </div>
      </div>
    </>
  );
};

export default SkillsTwo;
