import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="shane_tm_section">
        <div className="shane_tm_copyright">
          <div className="container">
            <div className="inner">
              <p>
                &copy; {new Date().getFullYear()} {" "}
                <Link to="/">
                SolutionHive - Moritz Küpper
                </Link>
                <span> - </span>
              <Link to="/impressum">Impressum</Link>
              <span> - </span>
              <Link to="/datenschutz">Datenschutz</Link>
              </p>
            </div>
            {/* End inner */}
          </div>
        </div>
        {/* End shane_tm_copyright */}
      </div>
    </>
  );
};

export default Footer;
