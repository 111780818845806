import React from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

const CallToActionTwo = () => {
    return (
      <div className="shane_tm_section" id="contact">
        <div
          className="shane_tm_talk bg_image_props"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + "img/callto-action/contact.jpg"
              })`,
          }}
        >
          <div className="shape">
            <img className="svg" src="/img/svg/paper.svg" alt="partners brand" />
          </div>
          {/* End shape */}

          <div className="background" id="talk">
            {/* TODO p was a before */}
            <p className="player"></p>
            <div className="overlay"></div>
          </div>
          {/* End background */}

          <div className="talk_inner">
            <div className="text" data-aos="fade-up" data-aos-duration="1200">
              <h3>Kontaktieren Sie mich!</h3>
            </div>

            <div className="short_info">
              <ul>
                <li>
                  <div className="list_inner">
                    <img
                      className="svg"
                      src="img/svg/location.svg"
                      alt="location"
                    />
                    <p>Diekstr. 9, 42551 Velbert</p>
                  </div>
                </li>
                {/* End silgle address */}

                <li>
                  <div className="list_inner">
                    <img
                      className="svg"
                      src="img/svg/email.svg"
                      alt="email"
                    />
                    <p>
                      <a href="mailto:moritz.kuepper@solution-hive.de">moritz.kuepper<div style={{display:'none'}}>[at]</div>@<div style={{display:'none'}}>42</div>solution-hive{/* Kommentar */}.{/* Kommentar */}de</a>
                    </p>
                  </div>
                </li>
                {/* End silgle address */}

                <li>
                  <div className="list_inner">
                    <img
                      className="svg"
                      src="img/svg/phone.svg"
                      alt="phone"
                    />
                    <p>
                      <a href="tel:+4920514057729">+49-2051-4057729</a>
                    </p>
                  </div>
                </li>
                {/* End silgle address */}

                {/* <li>
                    <div className="list_inner">
                      <img
                        className="svg"
                        src="img/svg/share.svg"
                        alt="share"
                      />
                      <Social />
                    </div>
                  </li> */}
                {/* End silgle address */}
              </ul>
            </div>
            {/* 
          <div
            className="button"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="100"
          >
            <button className="white-fill-bg" onClick={toggleModalOne}>
              Kontakt
            </button>
          </div> */}
          </div>
          {/* End talk_inner */}
        </div>
      </div>
    );
};

export default CallToActionTwo;
