import React from "react";
import Home from "../views/Home";
import NotFound from "../views/NotFound";
import Impressum from "../views/Impressum";
import Datenschutz from "../views/Datenschutz";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/impressum" element={<Impressum />} />
        <Route path="/datenschutz" element={<Datenschutz />} />
        <Route element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
