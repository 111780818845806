import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";

const Datenschutz = () => {
  return (
    <div className="impressum">
      <Header />
      {/* End Header Section */}

      <div className="shane_tm_section">
        <div className="shane_tm_impressum">
          <div className="container">
            <div className="impressum_inner">
              <div className="impressum_section">
                <h4>Datenschutz</h4>
                <p>Die Nutzung unserer Website ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unserer Seite personenbezogene Daten (beispielsweise Name, Anschrift, E-Mail-Adressen, im Rahmen einer Kontaktaufnahme) doch erhoben werden sollte, erfolgt dies, soweit es uns möglich ist, nur auf freiwilliger Basis (vgl. § 13 TMG). Die sensiblen Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben. Wir weisen darauf Aufmerksam machen, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich. Die Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien durch die Benutzung der Kontaktdaten, die im Rahmen der Erfüllung der Impressumspflicht gem. § 5 TMG preisgegeben werden, wird hiermit ausdrücklich widersprochen. Wir behalten uns ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails (insbesondere Newsletter), vor.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End shane_tm_impressum */}

      <div className="footer">
        <Footer />
      </div>
      {/* End Footer Section */}
    </div>
  );
};

export default Datenschutz;
