import React from "react";

const SocialShare = [
  {
    iconName: "linkedin",
    link: "https://www.linkedin.com/in/moritzkolb/",
    title: "LinkedIn"
  },
  {
    iconName: "xing",
    link: "https://www.xing.com/profile/MoritzM_Kuepper",
    title: "Xing",
  },
  {
    iconName: "freelance",
    link: "https://www.freelance.de/Freiberufler/moritzkolb",
    title: "freelance.de"
  },
];
const Social = () => {
  return (
    <>
      <ul className="social social-default position-relative">
        {SocialShare.map((val, i) => (
          <li key={i}>
            <a href={`${val.link}`} target="_blank" rel="noreferrer">
              <img
                className="svg"
                src={`/img/svg/social/${val.iconName}.svg`}
                alt="social"
                title={`${val.title}`}
              ></img>
            </a>
          </li>
        ))}
      </ul>
      {/* END social */}
    </>
  );
};

export default Social;
