import React from "react";

const Slider = () => {
  return (
    <>
      <div className="shane_tm_hero" id="home" data-style="two">
        <div className="background">
          <div
            className="image"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL + "img/slider/3.jpg"
                })`,
            }}
          ></div>
        </div>
        {/* End .background */}

        <div className="container">
            <div
              className="name_wrap"
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL + "img/slider/5.svg"
                  })`,
              }}
            >
            </div>
          {/* End content */}

          <div className="shane_tm_down loaded">
            <div className="line_wrapper">
              <div className="line"></div>
            </div>
          </div>
        </div>
        {/* End .container */}
      </div>
    </>
  );
};

export default Slider;
