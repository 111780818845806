import React from "react";
import Social from "../SocialTwo";

const About = () => {
  return (
    <>
      <div className="shane_tm_section" id="about">
        <div className="shane_tm_about">
          <div className="container">
            <div className="about_inner">
              <div className="left">
                <div
                  className="image"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  <img src="/img/about/3.jpg" alt="placeholder" />

                  <div
                    className="main"
                    style={{
                      backgroundImage: `url(${
                        process.env.PUBLIC_URL + "img/about/3.jpg"
                      })`,
                    }}
                  ></div>
                </div>
                {/* End image */}
              </div>
              {/* End left */}

              <div className="right">
                <div
                  className="shane_tm_title"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  <span>Über mich</span>
                  <h3>Erfahrener Softwareingenieur aus NRW</h3>
                </div>
                <div
                  className="text"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  <p>
                    Ich bin freiberuflicher Softwareingenieur und gehe dieser Tätigkeit sehr leidenschaftlich und engagiert nach.
                    Mit über vier Jahren Erfahrung als professioneller .NET Entwickler habe ich die Fähigkeiten und Kenntnisse erworben, um Ihr Projekt zu einem Erfolg zu machen.
                  </p>
                  <br/>
                  <p>
                    Mein vollständiges Profil und meinen Lebenslauf finden Sie in den sozialen Netzwerken!
                  </p>
                </div>
                <div data-aos="fade-up" data-aos-duration="1200">
                  <Social/>
                </div>

              </div>
              {/* End right */}
            </div>
          </div>
          {/* End container */}
        </div>
      </div>
    </>
  );
};

export default About;
